import React, { useEffect, useState } from "react";
import HeroBanner from "../Sections/HeroBanner";
import Tag from "../Component/Tag";
import SectionHeading from "../Component/SectionHeading";

const AgentRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [designation, setDesignation] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [taxFile, setTaxFile] = useState("");
  const [workingWith, setWorkingWith] = useState("");
  const [website, setWebsite] = useState("");
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selectedBusinessTypes, setSelectedBusinessTypes] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchBusinessTypes = async () => {
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/agents/get_business_types.php"
        );
        const result = await response.json();
        setBusinessTypes(result); // Assuming API response is an array of business types
      } catch (error) {
        console.error("Error fetching business types:", error);
      }
    };
    fetchBusinessTypes();
  }, []);

  const handleCheckboxChange = (businessType) => {
    setSelectedBusinessTypes((prevSelected) =>
      prevSelected.includes(businessType)
        ? prevSelected.filter((type) => type !== businessType)
        : [...prevSelected, businessType]
    );
  };

  const handleSubmit = async () => {
    // Clear previous error/success messages
    setError("");
    setSuccess("");

    // Validate required fields
    if (
      !name ||
      !contactPerson ||
      !designation ||
      !mobileNumber ||
      !emailAddress ||
      !address ||
      !city ||
      !state ||
      !country ||
      selectedBusinessTypes.length === 0
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    // Filter the selected business types
    const selectedBusinessTypesFiltered = selectedBusinessTypes.map((type) => ({
      business_id: type.id,
      business_name: type.name,
    }));

    const formData = {
      travel_agency_name: name,
      contact_person: contactPerson,
      designation: designation,
      mobile: mobileNumber,
      email: emailAddress,
      address: address,
      city: city,
      password: "123456", // Add password input if required
      state: state,
      country: country,
      abn_tax_file_no: taxFile,
      currently_working_with: workingWith,
      website: website,
      business_type: selectedBusinessTypesFiltered, // Only selected types
    };

    // console.log(formData);
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/agents/registration.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const result = await response.json();
      // console.log(result.success, result);

      if (result.success) {
        setSuccess("Registration successful.");
        setError("");

        // Reset all fields after success
        setName("");
        setContactPerson("");
        setDesignation("");
        setMobileNumber("");
        setEmailAddress("");
        setAddress("");
        setCity("");
        setState("");
        setCountry("");
        setTaxFile("");
        setWorkingWith("");
        setWebsite("");
        setSelectedBusinessTypes([]);
      } else {
        setError(result.error || result.success);
      }
      setLoading(false);
    } catch (error) {
      // console.log(error);
      console.error("Error submitting form:", error);
      setError(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <HomeHero />
      <Fleets />
      <PopularPlace />
      <WhyChooseUs />
      <Review /> */}
      <HeroBanner
        text={"Agent Registration"}
        imgUrl={"/assets/contact/hero-banner.png"}
      />
      <div className="container mx-auto">
        <div className="flex flex-col  gap-6 items-center mx-3 mt-12">
          <div className="lg:w-full">
            <Tag text={"Register Now"} />
            <SectionHeading mt={6} text={"Fill all required fields(*)."} />
          </div>
          <div className="w-full">
            <div className="flex gap-10 w-full justify-between mb-6 pb-3">
              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">Travel Agency Name*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full outline-none"
                    required
                    disabled={loading}
                    type="text"
                    placeholder="Enter Travel Agency Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col items-start  w-1/2 justify-start gap-4">
                <h2 className="font-semibold">Contact Person*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    required
                    disabled={loading}
                    type="text"
                    placeholder="Enter Contact Person"
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-10 w-full justify-between mb-6 pb-3">
              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">Designation*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    required
                    disabled={loading}
                    type="text"
                    placeholder="Enter Designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">Mobile Number*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    required
                    disabled={loading}
                    type="tel"
                    placeholder="Enter Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-10 w-full justify-between mb-6 pb-3">
              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">Email Address*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    required
                    disabled={loading}
                    type="email"
                    placeholder="Enter Email Address"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">Address*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    required
                    disabled={loading}
                    type="text"
                    placeholder="Enter Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-10 w-full justify-between mb-6 pb-3">
              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">City*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    required
                    disabled={loading}
                    type="text"
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">State*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    required
                    disabled={loading}
                    type="text"
                    placeholder="Enter State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-10 w-full justify-between mb-6 pb-3">
              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">Country*</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    required
                    disabled={loading}
                    type="text"
                    placeholder="Enter Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>

              {/* ABN/Tax File No */}
              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">ABN/Tax File No</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    disabled={loading}
                    type="text"
                    placeholder="Enter ABN/Tax File No"
                    value={taxFile}
                    onChange={(e) => setTaxFile(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Currently Working With */}
            <div className="flex gap-10 w-full justify-between mb-6 pb-3">
              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">Currently Working with</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    disabled={loading}
                    type="text"
                    placeholder="Enter Currently Working With"
                    value={workingWith}
                    onChange={(e) => setWorkingWith(e.target.value)}
                  />
                </div>
              </div>

              {/* Website */}
              <div className="flex flex-col w-1/2 items-start justify-start gap-4">
                <h2 className="font-semibold">Website</h2>
                <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                  <input
                    className="w-full  outline-none"
                    disabled={loading}
                    type="url"
                    placeholder="Enter Website"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              {/* Display checkboxes */}
              <div className="flex flex-col gap-3 mb-6 pb-3">
                <h2 className="font-semibold">Type of Business*</h2>
                <div className="w-full flex flex-wrap">
                  {businessTypes.length > 0 ? (
                    businessTypes.map((type, index) => (
                      <div
                        key={index}
                        className="w-1/3 flex  items-center gap-2 mb-3"
                      >
                        <input
                          type="checkbox"
                          id={`business-type-${type.id}`}
                          checked={selectedBusinessTypes.includes(type)}
                          onChange={() => handleCheckboxChange(type)}
                          disabled={loading}
                        />
                        <label htmlFor={`business-type-${type.id}`}>
                          {type.name}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>Loading business types...</p>
                  )}
                </div>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end mb-6">
                <button
                  onClick={handleSubmit}
                  className="bg-primary text-white py-2 px-4 rounded"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>

              {success ? (
                <div className="flex justify-center w-full mb-6 pb-6">
                  <div className="bg-primary_2 w-full font-medium text-primary py-2 px-5 rounded-md">
                    {success}
                  </div>
                </div>
              ) : error ? (
                <div className="flex justify-center w-full  mb-6 pb-6">
                  <div className="bg-red-100 w-full font-medium text-red-400 py-2 px-5 rounded-md">
                    {error}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentRegistration;
