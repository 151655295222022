import React from "react";
import { GoArrowUpRight } from "react-icons/go";
import { PiEnvelopeSimpleThin } from "react-icons/pi";
import { BsTelephonePlus } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTelegramPlane,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const creditCardIcons = [
  "/assets/home/mastercard.png",
  "/assets/home/paypal.png",
  "/assets/home/american-express.png",
  "/assets/home/visa.png",
];

const footerDetails = [
  {
    title: "Company",
    subDetails: [
      {
        subTitle: "About Fly Miles",
        link: "/about-us",
      },
      {
        subTitle: "Terms of use",
        link: "/terms-of-use",
      },
      {
        subTitle: "Privacy Policy",
        link: "/privacy-policy",
      },
      {
        subTitle: "Give Us Feedback",
        link: "/contact-us",
      },
    ],
  },
  {
    title: "Travel Agent Hub",
    subDetails: [
      {
        subTitle: "Travel Agent Registration",
        link: "/register",
      },
      {
        subTitle: "Instant Quote",
        link: "#",
      },
      {
        subTitle: "Rates / API Request",
        link: "#",
      },
    ],
  },
  {
    title: "Manage Booking",
    subDetails: [
      {
        subTitle: "WhatsApp Link",
        link: "https://wa.me/61413092724",
      },
      {
        subTitle: "Transfers",
        link: "https://packages.flymiles.com.au/get-booking",
      },
      // {
      //   subTitle:
      //     "Sign up for our mailing list to get latest updates and offers.",
      //   link: false,
      //   newsletter: true,
      // },
    ],
  },
  {
    title: "Contact",
    subDetails: [
      {
        subTitle: "info@flymiles.com.au",
        link: "mailto:info@flymiles.com.au",
        icon: <PiEnvelopeSimpleThin />,
      },
      {
        subTitle: "+61 413 092 724",
        link: "tel:+61413092724",
        icon: <BsTelephonePlus />,
      },
      {
        socialIcons: [
          {
            icon: <FaFacebookF />,
            bg: "bg-[#3B5998]",
            link: "https://www.facebook.com/flymiles.australia/",
          },
          {
            icon: <FaXTwitter />,
            bg: "bg-[#000000]",
            link: "https://twitter.com/flymiles?lang=en",
          },
          {
            icon: <FaInstagram />,
            bg: "bg-[#C13584]",
            link: "https://www.instagram.com",
          },
          {
            icon: <FaLinkedinIn />,
            bg: "bg-[#0077B5]",
            link: "https://linkedin.com/company/flymiles",
          },
        ],
      },
    ],
  },
];

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="bg-secondary hidden lg:block">
        <div className="container mx-auto flex gap-12 py-12 px-3 border-b border-[#D9D9D910]">
          <div className="w-1/2 flex items-center justify-between">
            <div className="flex items-center">
              <img src="/assets/home/headphone.png" alt="headphone" />
              <h1 className="text-white font-medium text-xl ms-5">
                <Link to={"/contact-us"}>
                  Need Any Support For <br /> Tour & Travels?
                </Link>
              </h1>
            </div>
            <Link
              style={{ boxShadow: "0px 10px 60px rgba(99, 171, 69, 0.65)" }}
              className="h-14 w-14 flex items-center justify-center bg-primary rounded-full text-white shadow-lg"
              to={"/"}
            >
              <GoArrowUpRight />
            </Link>
          </div>
          <div className="w-1/2 flex items-center justify-between">
            <h1 className="text-white font-medium text-xl me-5">
              Supported Payment <br /> Methods-
            </h1>
            <div className="w-[60%] flex flex-col items-center">
              <div className="flex items-center justify-between gap-2 mb-4 w-full">
                {creditCardIcons.map((x, index) => (
                  <img
                    key={index}
                    className="w-[calc(25%-6px)] h-10 object-contain bg-white p-1"
                    src={x}
                    alt="card"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto flex gap-12 px-3 py-12">
          {footerDetails.map((x, index) => (
            <div className="w-1/4" key={index}>
              <h1 className="text-white text-[22px] font-semibold mb-7">
                {x.title}
              </h1>
              {x.subDetails.map((y, yIndex) => (
                <div key={yIndex}>
                  {y.link && !y.icon ? (
                    <Link to={y.link} className="block text-[#E1E1E1] mb-3">
                      {y.subTitle}
                    </Link>
                  ) : null}
                  {y.link && y.icon ? (
                    <Link
                      to={y.link}
                      className=" text-[#E1E1E1] flex items-center gap-2 mb-3"
                    >
                      <p className="text-primary">{y.icon}</p>
                      {y.subTitle}
                    </Link>
                  ) : null}
                  {y.newsletter ? (
                    <>
                      <p className="block text-[#e1e1e1] mb-3">{y.subTitle}</p>
                      <form
                        className="bg-white p-3 rounded flex items-center gap-2 mt-4"
                        action=""
                      >
                        <input
                          className="bg-transparent w-full"
                          type="text"
                          placeholder="Email Address"
                        />
                        <button className="text-[#F7921E]">
                          <FaTelegramPlane size={18} />
                        </button>
                      </form>
                    </>
                  ) : null}
                  {y.socialIcons ? (
                    <>
                      <div className="flex items-center gap-5 mt-6">
                        {y.socialIcons.map((z, zIndex) => (
                          <Link
                            key={zIndex}
                            className={`h-[26px] w-[26px] flex justify-center items-center text-white rounded-full p-1 ${z.bg}`}
                            to={z.link}
                          >
                            {z.icon}
                          </Link>
                        ))}
                      </div>
                      <div>
                        <img
                          className="w-[220px] mt-6"
                          src="/assets/home/godaddy-site-seal.png"
                          alt="godaddy"
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className=" bg-[#101311]">
        <div className="container mx-auto flex items-center flex-col gap-3 md:flex-row justify-between py-6 px-3">
          <p className="text-[#E1E1E1] text-center lg:text-start">
            @{currentYear}{" "}
            <span className="text-[#F7921E] uppercase">Fly Miles pty ltd.</span>{" "}
            All Rights Reserved.
          </p>
          <Link className="text-[#e1e1e1] block" to="/contact-us">
            Contact Us
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
