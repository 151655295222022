import React, { useState, useEffect } from "react";
import { HiOutlineUserCircle } from "react-icons/hi2";
import CurrencyDropdown from "./CurrencyDropdown";
import CountryDropdown from "./CountryDropdown";
import { BsChevronDown } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { HiBars3 } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const socialDetails = [
  {
    icon: <FaFacebookF />,
    bg: "bg-[#3B5998]",
    link: "https://www.facebook.com",
  },
  {
    icon: <FaXTwitter />,
    bg: "bg-[#000000]",
    link: "https://www.x.com",
  },
  {
    icon: <FaInstagram />,
    bg: "bg-[#C13584]",
    link: "https://www.instagram.com",
  },
  {
    icon: <FaLinkedinIn />,
    bg: "bg-[#0077B5]",
    link: "https://www.linkedin.com",
  },
];

const Header = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [showMblMenu, setShowMblMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerClass, setHeaderClass] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("AU");
  const [selectedCurrency, setSelectedCurrency] = useState("AUD");

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);

      // Change background color when scrolled down 100 pixels
      if (window.scrollY >= 100) {
        setHeaderClass("bg-white shadow");
      } else {
        setHeaderClass("");
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  useEffect(() => {
    if (selectedCountry === "AU") {
      setSelectedCurrency("AUD");
    } else if (selectedCountry === "US") {
      setSelectedCurrency("USD");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCurrency === "AUD") {
      setSelectedCountry("AU");
    } else if (selectedCurrency === "USD") {
      setSelectedCountry("US");
    }
  }, [selectedCurrency]);

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  return (
    <div className={`fixed top-0 w-full z-[99] ${headerClass}`}>
      <div className="flex justify-between items-center container mx-auto p-3">
        <Link className="w-36" to="/">
          <img src="/assets/logo/logo.svg" alt="logo" />
        </Link>
        <div
          onClick={() => setShowMblMenu(false)}
          className={`block lg:hidden absolute h-lvh w-lvw bg-[#00000090] z-10 top-0 ${
            showMblMenu ? "left-0" : "-left-full"
          }`}
        ></div>
        <nav
          className={`block lg:flex w-[300px] lg:w-auto absolute lg:relative bg-white lg:bg-transparent min-h-lvh lg:min-h-0 top-0 left-0 px-4 lg:px-0 lg:space-x-4 z-50 ${
            showMblMenu
              ? "left-0 lg:left-auto"
              : "left-[-300px] lg:left-auto transition-all"
          }`}
        >
          <Link className="block lg:hidden my-5" to="/">
            <img src="/assets/logo/logo.svg" alt="logo" />
          </Link>
          <button
            onClick={() => setShowMblMenu(!showMblMenu)}
            className="lg:hidden absolute top-5 right-4"
          >
            <IoCloseOutline size={24} />
          </button>
          <Link
            to={"/"}
            className={`text-secondary font-medium border-b lg:border-b-0 pb-3 lg:pb-0 block w-full lg:w-auto text-lg ${
              activeMenuItem === "Home" ? "lg:border-b-2 lg:border-primary" : ""
            }`}
            onClick={() => handleMenuItemClick("Home")}
          >
            Home
          </Link>
          <div className="group relative">
            <button
              className={` text-secondary font-medium border-b lg:border-b-0 py-3 lg:py-0 w-full lg:w-auto flex justify-between items-center text-lg ${
                activeMenuItem === "About Us"
                  ? "border-b-0 lg:border-b-2 lg:border-primary"
                  : ""
              }`}
              onClick={() =>
                activeMenuItem === "About Us"
                  ? handleMenuItemClick("About Us lg")
                  : handleMenuItemClick("About Us")
              }
            >
              About Us
              <div
                className={`lg:hidden bg-primary p-1 rounded text-white ${
                  activeMenuItem === "About Us" ? " rotate-180" : ""
                }`}
              >
                <BsChevronDown />
              </div>
            </button>
            <div
              className={`relative lg:absolute lg:group-hover:block ${
                activeMenuItem === "About Us" ? "block lg:hidden" : "hidden"
              } `}
            >
              <div className=" py-3 ps-3 pe-0 lg:p-3 lg:mt-3 bg-white lg:shadow-xl lg:w-[250px] rounded-lg space-y-2 lg:border">
                <Link to="/about-us" className="block border-b pb-3">
                  About Fly Miles
                </Link>
                <Link
                  to="/services"
                  className="block border-b lg:border-b-0 pb-3 lg:pb-0"
                >
                  Our Services
                </Link>
              </div>
            </div>
          </div>
          <div className="group relative">
            <button
              className={` text-secondary font-medium border-b lg:border-b-0 py-3 lg:py-0 w-full lg:w-auto flex justify-between items-center text-lg ${
                activeMenuItem === "Bookings"
                  ? "border-b-0 lg:border-b-2 lg:border-primary"
                  : ""
              }`}
              onClick={() =>
                activeMenuItem === "Bookings"
                  ? handleMenuItemClick("Bookings lg")
                  : handleMenuItemClick("Bookings")
              }
            >
              Bookings
              <div
                className={`lg:hidden bg-primary p-1 rounded text-white ${
                  activeMenuItem === "Bookings" ? " rotate-180" : ""
                }`}
              >
                <BsChevronDown />
              </div>
            </button>
            <div
              className={`relative lg:absolute lg:group-hover:block ${
                activeMenuItem === "Bookings" ? "block lg:hidden" : "hidden"
              } `}
            >
              <div className=" py-3 ps-3 pe-0 lg:p-3 lg:mt-3 bg-white lg:shadow-xl lg:w-[250px] rounded-lg space-y-2 lg:border">
                <Link
                  to="/get-booking"
                  className="block border-b lg:border-b-0 pb-3 lg:pb-0"
                >
                  Manage Booking
                </Link>
              </div>
            </div>
          </div>
          <div className="group relative pb-[146px] lg:pb-0">
            <button
              className={` text-secondary font-medium border-b lg:border-b-0 py-3 lg:py-0 w-full lg:w-auto flex justify-between items-center text-lg ${
                activeMenuItem === "Help"
                  ? "border-b-0 lg:border-b-2 lg:border-primary"
                  : ""
              }`}
              onClick={() =>
                activeMenuItem === "Help"
                  ? handleMenuItemClick("Help lg")
                  : handleMenuItemClick("Help")
              }
            >
              Help & Support
              <div
                className={`lg:hidden bg-primary p-1 rounded text-white ${
                  activeMenuItem === "Help" ? " rotate-180" : ""
                }`}
              >
                <BsChevronDown />
              </div>
            </button>
            <div
              className={`relative lg:absolute lg:group-hover:block ${
                activeMenuItem === "Help" ? "block lg:hidden" : "hidden"
              } `}
            >
              <div className="lg:mt-3 bg-white lg:shadow-xl lg:w-[250px] py-3 ps-3 pe-0 lg:p-3 rounded-lg space-y-2 lg:border">
                <Link to="/contact-us" className="block border-b pb-3">
                  Contact Us
                </Link>
                <Link
                  target="_blank"
                  to="https://wa.me/61413092724"
                  className="block border-b pb-3"
                >
                  Chat With Us
                </Link>
                <Link to="/terms-of-use" className="block border-b pb-3">
                  Terms of Use
                </Link>
                <Link
                  to="/privacy-policy"
                  className="block border-b lg:border-b-0 pb-3 lg:pb-0"
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
          <div className="flex gap-3 flex-col items-center absolute lg:hidden !bottom-6 w-full left-0">
            <a href="mailto:info@flymiles.com.au">info@flymiles.com.au</a>
            <a href="tel:61413092724">+61 413 092 724</a>
            <div className="flex items-center gap-3">
              {socialDetails.map((x, index) => (
                <Link
                  key={index}
                  className={`h-[26px] w-[26px] flex justify-center items-center text-white rounded-full p-1 ${x.bg}`}
                  to={x.link}
                >
                  {x.icon}
                </Link>
              ))}
            </div>
          </div>
        </nav>
        <div className="flex items-center gap-1">
          <button
            onClick={() => setShowMblMenu(true)}
            className="h-[30px] w-[30px] flex items-center justify-center border-black border me-1 lg:me-3 rounded-full lg:hidden"
          >
            <HiBars3 size={24} />
          </button>
          <CountryDropdown
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
          <CurrencyDropdown
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
          />
          <Link
            to={"/agent-registration"}
            className="flex items-center gap-2 lg:bg-primary lg:text-white lg:p-3 rounded-lg"
          >
            <HiOutlineUserCircle size={24} />
            <p className="font-semibold hidden lg:block">Agent Login</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
